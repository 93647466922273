@tailwind base;
@tailwind components;
@tailwind utilities;



.btn {
  /* background: #0A2342; */
  background: #283E51;
  box-shadow: 0px 4.42184px 107.23px #1C2E3F;
}

.bg-color {
  background: #283E51;
}

.btn:hover {
  background: #1C2E3F;
}

ul li a:hover {
  color: #87CEEB;
}

.btn-active {
  background-color: #1C2E3F;
  border-bottom: 2px solid #0A2342;
  color: #87CEEB;
}

.btn-inactive {
  background-color: #fafafa;
  opacity: 20;
}

* {
  font-family: 'Open Sans', sans-serif;
}


@media (max-width: 768px) {
  .swiper {
    height: 100vh;
  }
}

/* Adjust height for even smaller screens */
@media (max-width: 640px) {
  .swiper {
    height: 100vh;
  }
}

.tableContainer{
    background-color: #1C2E3F;
    /* border-radius: 20px; */
    width: 100%;
    height: auto;
    padding: 14px;
    overflow: scroll;
}

/* Table thead{
    position: sticky;
    top: 0px;
    margin: 0 0 0 0;
} */

.swiper {
  /* width: 100%;
  height: auto; */
  --swiper-pagination-color: #fafafa;
  --swiper-pagination-bullet-inactive-color: #808080;
  --swiper-pagination-bullet-inactive-opacity: 1;
  --swiper-pagination-bullet-size: 16px;
  --swiper-pagination-bullet-horizontal-gap: 6px;

}

@media screen and (min-width: 300px) {
  .swiper {
    height: auto;
    margin-top: 20px;
  }
}


@media screen and (min-width: 768px) {
  .swiper {
    height: auto;
  }
}


/* .swiper-slide {
  text-align: center;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
} */

/* .swiper-slide img {
  display: block;
  width: 100%;
  height: auto;
  object-fit: contain;
}  */

/* .swiper {
  margin-left: auto;
  margin-right: auto;
} */



.anim_gradient {
  background: linear-gradient(to right, #283E51 0%, #0A2342 100%);
  background-size: "400% 400%";
  animation: "gradient 25s ease infinite";
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}


@layer base {
  body {
    @apply text-lg text-primary leading-8;
  }

  .h2 {
    @apply text-[32px] mb-6 tracking-[10%] uppercase text-primary;
  }

  .h3 {
    @apply font-semibold text-[26px] mb-6 leading-[46px] text-primary;
  }

  .btn {
    @apply rounded-full text-primary font-medium;
  }

  .btn-sm {
    @apply h-[48px] px-6 text-sm text-primary;
  }

  .btn-lg {
    @apply h-[56px] px-10 text-base text-primary;
  }

  .btn-link {
    @apply text-base text-primary;
  }

  .section {
    @apply py-8 lg:py-24 lg:h-screen flex items-center text-primary;
  }
}